import React from 'react'
import * as styles from '../assets/styles.module.scss'
import Heart from '../assets/vectors/heart.svg'
import LogoWhite from '../assets/vectors/callladies-white.svg'

const Footer = () => {
    return (
        <footer className={styles.Footer}>
            <div className={styles.CallLadies}>
                <LogoWhite />
            </div>

            <nav className={styles.Menu}>
                <a href={`/models`}>Models</a>
                <a href={`/`}>Support</a>
                <a href={`/`}>Become a Model</a>
                <a href={`/privacy`}>Privacy Policy</a>
                <a href={`/terms`}>Terms of Service</a>
            </nav>

            <div className={styles.Disclaimer}>
                AVISO: CALL-LADIES CONTIENE MATERIAL EXPLÍCITO NO APTO POR MENORES
                DE EDAD DESARROLLADO EXCLUSIVAMENTE PARA ADULTOS. SI TE OFENDES
                FACILMENTE POR VER FOTOS DESNUDOS O DONDE VIVES NO ERES LEGALMENTE
                APTO PARA VER DICHO CONTENIDO DA CLICK EN ESTE <a href={`https://disney.com`}>LINK</a>.
            </div>

            <div className={styles.Copyright}>
                © 2020 CALL-LADIES. TODOS LOS DERECHOS RESERVADOS.
            </div>
        </footer>
    )
}

export default Footer